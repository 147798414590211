/* html {
  font-family: sans-serif;
  font-size: 12px;
} */

.Grid {
  border: 0px solid #d9dddd;
}

.GridItem {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f0;
}
