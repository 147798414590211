.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* html,
body {
  width: 100%;
  height: 100%;
} */
body {
  background-color: #1E1E1E;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  display: flex;
  min-width: 100%;
  min-height: 350px;
  overflow-x: auto; 
}

.card::-webkit-scrollbar {
  display: none;
}

.card--content {
  background-color: #e74c3c;
  min-width: 250px;
  margin: 50px;
}

.card--content:first-child {
  margin-left: 0;
}

.card--content:last-child {
  margin-right: 0;
}
