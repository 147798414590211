

.time-header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #1E1E1E;
}

.time-header-elem {
  border-style: solid;
  border-radius: 10px;
  height: 130px;
  width: 30%;
  padding: 10px;
  background-color: #1E1E1E;
  border-color: #3C4042;
}

.title{
  font-weight: lighter;
  font-size: 24px;
  padding: 5px;
  color: #BE88FF;
}

.title-modal{
  font-weight: lighter;
  font-size: 24px;
  color: #BE88FF;
}

.title-modal-second{
  font-weight: lighter;
  font-size: 18px;
  color: #BE88FF;
  margin-bottom: 10px;
}

.button-primary {
  background-color: #BE88FF; /* Green */
  border: none;
  border-radius: 5px;
  color: #1E1E1E;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin: 10px;
}
.button-primary:hover{
  cursor: pointer;
  opacity: 0.8;
}
.button-primary:disabled{
  opacity: 0.1;
}

.button-secondary {
  background-color: #1E1E1E; /* Green */
  border: none;
  border-radius: 5px;
  color: #BEC1C5;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  opacity: 1;
  margin-bottom: 10px;
}
.button-secondary:hover{
  cursor: pointer;
  opacity: 0.8;
}
.button-secondary:disabled{
  opacity: 0.1;
}

.header-text{
  font-size: 14px;
  font-weight: 200;
  color: #BEC1C5;
  padding: 5px;
}

.modal-elem{
  border-radius: 10px;
  height: auto;
  background-color: #1E1E1E;
  padding: 20px;
  flex-grow: 1;
}

.modal-elem-owner{
  margin-right: 20px;
}

.modal-elem-historical{
  width: 100%;
  margin-top: -20px;
}

.modal-container{
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: stretch;
}

.modal-top-container {
  display: flex;
  align-items: top;
  justify-content: space-between
}

.header-browse-bottom-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}
.header-browse-bottom-content {

}

.modal-input{
  width: 100%;
  border: 0px solid;
  border-radius: 5px;
  background-color: #3C4042;
  color: #BEC1C5;
  border: 1px solid #3C4042;
  padding: 3px;
}
.modal-input:focus {
  border: 1px solid #BE88FF;
}

.header-input{
  width: 50%;
  border: 0px solid;
  border-radius: 5px;
  background-color: #3C4042;
  color: #BEC1C5;
  border: 1px solid #3C4042;
  padding: 5px;
}
.header-input:focus {
  border: 1px solid #BE88FF;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}



.button-primary-modal {
  background-color: #BE88FF; /* Green */
  border: none;
  border-radius: 5px;
  color: #1E1E1E;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
  margin-right: 20px;
}
.button-primary-modal:hover{
  cursor: pointer;
  opacity: 0.8;
}
.button-primary-modal:disabled{
    opacity: 0.1;
}


.button-primary-modal-buy{
  margin-bottom: 20px;
}

.hero-header {
  border: 1px solid #3C4042;
}